::ng-deep {
  .ng-select-container {
    min-height: calc(1.5em + 0.75rem + 2px) !important;
    // box-shadow: 0px 3px 2px #0000001a;
    // border: 1px solid #e0e0e0 !important;
  }

  .ng-select .ng-control {
    border: none;
    padding: 10px;
    // box-shadow: 0px 3px 2px #0000001a;
    border-radius: 5px;
  }

  .ng-select input {
    border: 1px solid #e0e0e0;
    border-radius: 5px;
  }
}

.box-s {
  box-shadow: 0 0 3px rgb(0 0 0 / 0.1) !important;
}

.m-t-1 {
  margin-top: -1px;
}

.ng-select.bt-show ::ng-deep .ng-select-container {
  min-height: 0px !important;
  height: 25px;
}

.is-invalid {
  border-color: red !important;
}

.normal-input-form {
  height: 36px;
  border-radius: 4px !important;
  border: 1px solid #A4A4A4 !important;
  background-color: #ffffff !important;
}