.modal-body {
  padding: 3rem;
  background-color: #ffffff;
}

.modal-header {
  background-color: white;
}

.modal-footer {
  background-color: #ffffff;
}
