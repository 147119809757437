@use 'variables';

.btn {
  padding: 4px 15px;
  border-radius: 5px;
  font-weight: 700;
  font-size: 1rem;
  transition: 300ms all;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 7%);
  cursor: pointer;
}

.btn-select {
  background: transparent;
  border-radius: 5px;
  border: 1px solid #dedede;
  padding: 0 15px;
}

.btn-success {
  background: #03a9f3;
  border-color: #03a9f3;
  color: #f2f2f2;
}

.btn-primary {
  background-color: #252259 !important;
  color: #f2f2f2;
  border: none;
}

.btn-outline-primary {
  border-color: #252259;
  color: #252259;
}

.btn-green {
  background: #19bc9c;
  border-color: #19bc9c;
  color: white !important;
}

.btn-outline-lightcoral {
  @include button-outline-variant(lightcoral);
}

.btn-outline-yale-blue {
  @include button-outline-variant(variables.$yale-blue);
}

.btn-transparent {
  border: none;
  box-shadow: none;
  background-color: transparent;
}

.btn-white {
  @include button-variant(white, white);
  box-shadow: 0 0 3px 1px #c0c0c0;
}

.btn-yale-blue  {
  @include button-variant(variables.$yale-blue, variables.$yale-blue);
}

.btn-w-80 {
  min-width: 80px !important;
}

.icon-close {
  fill: #7f7f7f;

  &:hover {
    fill: #000;
  }
}