.p-datatable .p-column-resizer {
  width: 1px !important;
}

.p-datatable-resizable > .p-datatable-wrapper {
  overflow-x: inherit !important;
}

.p-datatable-resizable .p-datatable-thead > tr > th {
  background-color: transparent !important;
  vertical-align: middle !important;
  padding: 0.75rem 1.25rem !important;
  border-bottom: 1px solid #dcdcdc !important;
  font-family: 'Prompt', sans-serif !important;
}

.p-datatable .p-datatable-tbody > tr > td {
  white-space: normal !important;
}

@media screen and (max-width: 960px) {
  .p-datatable-resizable .p-datatable-thead > tr > th {
    white-space: normal !important;
  }
}

@media screen and (max-width: 1280px) {
  .p-datatable .p-column-resizer {
    display: none !important;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    white-space: normal !important;
  }
}

.table tbody td {
  font-family: 'Prompt', sans-serif !important;
}
.p-datatable-thead tr th {
  &:hover {
    .p-column-resizer {
      border: solid 1px #eee !important;
    }
  }
}
