.progress {
  border-radius: 1.25rem;
  margin-top: 0.75rem;
  margin-bottom: 1.25rem;
}

.sms .progress-bar {
  background-color: #59C3D4;
}

.company .progress-bar {
  background-color: #F5C26B;
}

.eTimeStamp .progress-bar {
  background-color: #53BDA5;
}

.selfieVideo .progress-bar {
  background-color: #0049FF;
}

.signing .progress-bar {
  background-color: #F07857;
}

.ndid .progress-bar {
  background-color: #5C62D6;
}

.thaicom .progress-bar {
  background-color: #CBEA4D;
}
