// To put a icon that want to have hover
@mixin btn-icon-circle($size: 1em) {
  align-items: center;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  height: $size;
  justify-content: center;
  width: $size;
  transition: 0.3s;

  span {
    font-size: $size * 1.1;
  }

  &:hover {
    box-shadow: 0 0 6px -2px #111;
  }
}

@mixin circle-size($radius) {
  @include size($radius);
  border-radius: 50%;
}

@mixin disable-scrollbar {
  scrollbar-width: none !important; /* Firefox */
  -ms-overflow-style: none !important; /* IE 10+ */
  &::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
    background: transparent !important; /* Chrome/Safari/Webkit */
  }
}

@mixin size($width, $height: $width) {
  height: $height;
  width: $width;
}
