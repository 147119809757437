$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1540px,
);

.page-item.disabled .page-link {
  background: #ffffff;
  color: #dfe6e9;
  border-color: #dfe6e9;
}

.page-item.active .page-link {
  background: variables.$royal-blue;
  color: #ffffff;
  border-color: variables.$royal-blue;
  z-index: 0;
}

.page-link {
  background: #ffffff;
  color: #0f2e4b;
  border-color: #dfe6e9;

  &:hover {
    background: #dfe6e9;
    color: #0f2e4b;
    border-color: #dfe6e9;
  }

  &:focus {
    box-shadow: none;
  }
}

.table thead th {
  vertical-align: bottom;
  border: none;
  text-align: left;
}

// Bootstrap partial imports to keep the bloat away
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
